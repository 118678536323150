<template>
  <div>
    <div class="body-1">
      VHS, Super 8, 16mm oder ein altes Bild? Wir digitalisieren Ihre
      Erinnerungen wie Videos und Bilder für Sie. Auch Großaufträge wie
      Unternehmensarchive!
    </div>

    <div>
      <div class="text-h6 title">
        Folgende Formate können wir für Sie digitalisieren...
      </div>
    </div>

    <div class="exp-panels">
      <v-expansion-panels focusable>
        <v-expansion-panel>
          <v-expansion-panel-header
            >Videos und Kassetten</v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <div class="has-margin-top text-body-1">
              <ul>
                <li>VHS, VHS-C, S-VHS, S-VHS-C</li>
                <li>Hi8, Normal 8, Digital 8</li>
                <li>Mini-DV, DV</li>
                <li>BetaMax</li>
              </ul>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <div>Zelluloidfilme und Filmrollen</div></v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <div class="has-margin-top text-body-1">
              <ul>
                <li>Normal 8, Doppel 8, Super 8</li>
                <li>16mm und 35mm</li>
              </ul>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <div>Dias, Negative und Bilder</div></v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <div class="has-margin-top text-body-1">
              Alle Dia- und Negativgrößen von Kleinstbildkamera (Minox) bis
              Großformat.
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <div>
      <div class="text-h6 title">Ihre Ausgabemöglichkeiten</div>
      <div class="text-body-1">
        <ul>
          <li>Blu-Ray, DVD, CD und/oder USB-Stick/Festplatte</li>
          <li>
            Als Datei(Archiv) über unseren sicheren Dateiaustauschdienst, FTP
            oder per Mail
          </li>
          <li>
            Für Bilder zusätzlich: Als hochwertige Abzüge in matt/glanz. Auch
            vielfach vergößert...
          </li>
        </ul>
      </div>
    </div>
    <div>
      <div class="text-h6 title">Unsere Qualität</div>
      <div class="text-body-1">
        Wir haben über die letzten <b>20 Jahre</b> die Qualität der
        Digitalisierung stetig verbessert und können nun ihre Erinnerungen über
        Verfahren wie <b>Upscaling</b>,
        <b>KI-Bildqualitätsverbesserung</b> und/oder
        <b>Nachkolorierung</b> farblich sowie mit einer Auflösung bis
        <b>Full-HD</b> oder <b>4K</b> in einer ganz neuen Dimension aufbereiten.
      </div>
    </div>
    <div class="request">
      <contact-now title="Jetzt digitalisieren!"></contact-now>
    </div>
  </div>
</template>

<script>
import ContactNow from "../../../components/nav/ContactNow";

export default {
  components: { ContactNow },
};
</script>

<style scoped>
.exp-panels {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.title {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.spacer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.has-margin-top {
  margin-top: 1rem;
}
</style>